import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const categories = [
  {
    text: "Filtering",
    description: (
      <>
        Crumbs blocks trackers, cookies and other malicious scripts by using the <b>EasyPrivacy</b> list
        and <b>EasyList Cookie</b> list.
      </>
    ),
    links: [
      {
        text: 'Cookie Consent Popups',
        url: '/filtering/cmp',
      },
      {
        text: 'CMP events listener',
        url: '/filtering/cmpuishown',
      },
      {
        text: 'Network Requests',
        url: '/filtering/network-requests',
      },
      {
        text: 'Marketing Parameters',
        url: '/filtering/utm',
      }
    ]
  },
  {
    text: "DOM properties",
    description: (
      <>
        Crumbs changes some DOM properties in order to better protect you. We also expose Do Not Track and Global
        Privacy Protection flags.
      </>
    ),
    links: [
      {
        text: 'document.referrer',
        url: '/dom/referrer',
      },
      {
        text: 'navigator.doNotTrack',
        url: '/dom/dnt',
      },
      {
        text: 'navigator.globalPrivacyControl',
        url: '/dom/gpc',
      }
    ],
  },
  {
    text: "Headers",
    description: (
      <>
        Crumbs adds, removes and modifies HTTP headers.
      </>
    ),
    links: [
      {
        text: 'Set headers',
        url: '/headers/set-headers',
      },
      {
        text: 'Removed headers',
        url: '/headers/removed-headers',
      },
    ],
  },
  {
    text: "Fingerprinting",
    description: (
      <>
        Crumbs anonymizes some of your browser characteristics in order to protect you from being uniquely
        identifiable on the internet.
      </>
    ),
    links: [
      {
        text: 'navigator properties',
        url: '/fingerprinting/navigator',
      },
      {
        text: 'FingerprintJS V2',
        url: '/fingerprinting/debug',
      },
      {
        text: 'CoverYourTracks.eff.org',
        url: 'https://coveryourtracks.eff.org/',
      },
      {
        text: 'Turnstile: I`m not a robot (Cloudflare)',
        url: 'https://captcha.website/',
      }
    ],
  },
  {
    text: "Cookies",
    description: (
      <>
        Crumbs applies different strategies to minimize the tracking using 3rd party cookies.
      </>
    ),
    links: [
      {
        text: '3rd party cookies expiration [ext]',
        url: '/cookies/cookies-expiration',
      },
      {
        text: '3rd party cookies removal',
        url: '/cookies/cookies-removal',
      },
      {
        text: 'Cookie Sandboxing',
        url: '/cookies/sandboxing',
      },
    ],
  },
]


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/logo-small.svg"
        placeholder="none"
        loading="eager"
        alt="Crumbs logo"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        style={{ marginBottom: `var(--space-3)` }}
      />

      <h1>
        Welcome to <b>Crumbs Test Pages!</b>
      </h1>
    </div>
    <ul className={`list-disc ${styles.list}`}>
      {categories.map(category => (
        <li key={category.text} className={styles.listItem}>
          <b className={styles.listItemLink}>
            {category.text} ↗
          </b>
          <p className={styles.listItemDescription}>{category.description}</p>
          <ul className="list-disc">
            {category.links.map(link => (
              <li key={link.text}>
                <Link to={link.url}>
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
